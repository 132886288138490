<div class="container mt-4">
  <div class="row justify-content-center">
    <div class="col-12 col-sm-10 col-md-8">

      <form (ngSubmit)="updateImage()" (keydown)="debounceUpdateImage()">

        <div class="row">

          <div class="col-6 col-xs-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>1. Titel</mat-label>
              <input matInput placeholder="Die" value="Die" [(ngModel)]="content.first" name="first">
            </mat-form-field>
          </div>

          <div class="col-6 col-xs-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>2. Titel</mat-label>
              <input matInput placeholder="Mitte" value="Mitte" [(ngModel)]="content.second" name="second">
            </mat-form-field>
          </div>

          <div class="col-6 col-xs-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>1. Untertitel</mat-label>
              <input matInput placeholder="Freiheit" value="Freiheit" [(ngModel)]="content.sub1" name="sub1">
            </mat-form-field>
          </div>

          <div class="col-6 col-xs-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>2. Untertitel</mat-label>
              <input matInput placeholder="Solidarität" value="Solidarität" [(ngModel)]="content.sub2" name="sub2">
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100" style="margin-bottom:-1.34375em;">
              <mat-label>3. Untertitel</mat-label>
              <input matInput placeholder="Verantwortung" value="Verantwortung" [(ngModel)]="content.sub3" name="sub3">
            </mat-form-field>
          </div>

        </div>

        <div class="row justify-content-center mt-3 mb-3">
          <div class="col-auto">
            <button mat-raised-button type="button" color="accent" style="background-color: rgb(255, 155, 0); color: white;" (click)="randomWords()">Zufall</button>
          </div>
        </div>

      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <img [src]="image" width="100%" height="100%" />
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-auto mt-5 mb-5">
      <button *ngIf="isMobile" mat-raised-button color="primary" matTooltip="Auf das Bild tippen und halten, um es zu speichern."
              matTooltipPosition="above" #tooltip="matTooltip" (click)="tooltip.toggle()">Speichern</button>
      <button *ngIf="!isMobile" mat-raised-button color="primary" (click)="save()">Speichern</button>
    </div>
  </div>
</div>
