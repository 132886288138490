import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import { interval, Subject, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

declare let FontFace: any;

const CENTER_WIDTH_TITLE = 497;

interface Content {
  first: string;
  second: string;
  sub1: string;
  sub2: string;
  sub3: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

  private defaultContent: Content = {
    first: 'Die',
    second: 'Mitte',
    sub1: 'Freiheit',
    sub2: 'Solidarität',
    sub3: 'Verantwortung'
  };

  content: Content = {
    first: this.defaultContent.first,
    second: this.defaultContent.second,
    sub1: this.defaultContent.sub1,
    sub2: this.defaultContent.sub2,
    sub3: this.defaultContent.sub3,
  }

  random: Content[] = [
    {
      first: 'Die',
      second: 'Windfahne',
      sub1: 'Unbeständig',
      sub2: 'Wankelmütig',
      sub3: 'Flexibel',
    },
    {
      first: 'Die',
      second: 'Fritte',
      sub1: 'Knackig',
      sub2: 'Salzig',
      sub3: 'Lecker',
    },
    {
      first: 'Die',
      second: 'Nuss',
      sub1: 'Hart',
      sub2: 'Stark',
      sub3: 'Braun',
    },
    {
      first: 'Der',
      second: 'Löffel',
      sub1: 'Gebogen',
      sub2: 'Praktisch',
      sub3: 'Grau',
    },
    {
      first: 'Die',
      second: 'Zunge',
      sub1: 'Pink',
      sub2: 'Frech',
      sub3: 'Sauer',
    },
    {
      first: 'Die',
      second: 'Steuern',
      sub1: 'Teuer',
      sub2: 'Zu hoch',
      sub3: 'Verschwenderisch',
    },
    {
      first: 'Der',
      second: 'Baum',
      sub1: 'Stark',
      sub2: 'Langlebig',
      sub3: 'Schön',
    },
    {
      first: 'Die',
      second: 'Biene',
      sub1: 'Eifrig',
      sub2: 'Flink',
      sub3: 'Wertvoll',
    },
    {
      first: 'Die',
      second: 'Banane',
      sub1: 'Krumm',
      sub2: 'Gelb',
      sub3: 'Exotisch',
    },
  ];

  loading = false;

  // defaultImage = 'assets/example.jpeg';
  defaultImage = 'assets/bg.png';
  image = this.defaultImage;

  private fontLoaded = false;

  updateImage$: Subject<any> = new Subject();
  private subscription: Subscription;

  isMobile = false;

  constructor(private deviceService: DeviceDetectorService) {
    this.isMobile = !this.deviceService.isDesktop();
  }

  async ngOnInit() {
    await this.loadFonts();

    this.subscription = this.updateImage$.pipe(
      debounce(() => interval(500))
    ).subscribe(this.updateImage.bind(this));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.fontLoaded) {
      this.updateImage();
    }
  }

  debounceUpdateImage() {
    this.updateImage$.next();
  }

  private async loadFonts() {
    const fonts = [
      new FontFace('FontRegular', 'url(/assets/fonts/font-regular.woff)'),
      new FontFace('FontMedium', 'url(/assets/fonts/font-medium.woff)'),
      new FontFace('FontExtraBold', 'url(/assets/fonts/font-extra-bold.woff)')
    ];

    for (const font of fonts) {
      const f = await font.load();
      (document as any).fonts.add(f);
    }
    this.fontLoaded = true;

    this.updateImage();
  }

  updateImage() {
    const titleFont = 'FontExtraBold';

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const source = new Image();
    source.src = this.defaultImage;
    source.crossOrigin = 'Anonymous';
    source.onload = () => {

      canvas.height = source.height;


      // Measure max width of texts to center image on another canvas
      context.font = `160px ${titleFont}`;
      context.textAlign = 'right';
      const titleWidth = Math.max(context.measureText(this.content.first).width, context.measureText(this.content.second).width);

      context.font = '55px FontRegular';
      const subtitle2Width = this.content.sub2.length > 0 ? context.measureText(this.content.sub2).width : 0;
      const subtitle1Width = this.content.sub1.length > 0 ? context.measureText(this.content.sub1).width + (subtitle2Width > 0 ? subtitle2Width + 23 : 0) : subtitle2Width;
      const subtitle3Width = this.content.sub3.length > 0 ? context.measureText(this.content.sub3).width : 0;

      const maxWidth = Math.max(titleWidth, subtitle1Width, subtitle3Width);

      if (maxWidth > CENTER_WIDTH_TITLE) {
        canvas.width = source.width + maxWidth - CENTER_WIDTH_TITLE;

        // white background
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);

        // TODO DEBUG
        // context.fillStyle = "#00FFFF";
        // context.fillRect(0,0,canvas.width, canvas.height);


        context.drawImage(source, (canvas.width - source.width), 0);
      } else {
        canvas.width = source.width;
        context.drawImage(source, 0, 0);
      }

      const widthDifference = canvas.width - source.width;

      // Font color
      context.fillStyle = '#003c70';

      const doubleT = (text: string, x: number, y: number) => {
        const index = text.lastIndexOf('tt') + 1;
        const text1 = text.substring(0, index);
        const text2 = text.substring(index);

        context.font = `160px ${titleFont}`;
        context.textAlign = 'right';
        context.fillText(text2, x, y);

        const widthText2 = context.measureText(text2).width - 9;

        if (text1.includes('tt')) {
          return widthText2 + doubleT(text1, x - widthText2, y);
        }

        context.font = `160px ${titleFont}`;
        context.textAlign = 'right';
        context.fillText(text1, x - widthText2, y);

        return widthText2 + context.measureText(text1).width;
      };

      let titleLength;

      if (this.content.first.includes('tt')) {
        titleLength = doubleT(this.content.first, (source.width / 1.63) + widthDifference, canvas.height * 0.41);
      } else {
        context.font = `160px ${titleFont}`;
        context.textAlign = 'right';
        context.fillText(this.content.first, (source.width / 1.63) + widthDifference, canvas.height * 0.41);
        titleLength = context.measureText(this.content.first).width;
      }

      if (this.content.second.includes('tt')) {
        titleLength = Math.max(titleLength, doubleT(this.content.second, (source.width / 1.63) + widthDifference, canvas.height * 0.535));
      } else {
        context.font = `160px ${titleFont}`;
        context.textAlign = 'right';
        context.fillText(this.content.second, (source.width / 1.63) + widthDifference, canvas.height * 0.535);

        titleLength = Math.max(titleLength, context.measureText(this.content.second).width);
      }


      let widthSub2 = 0;
      if (this.content.sub2 && this.content.sub2.length > 0) {
        context.font = '55px FontRegular';
        context.textAlign = 'right';
        context.fillText(`${this.content.sub2}.`, (source.width / 1.642) + widthDifference, canvas.height * 0.719);
        widthSub2 = context.measureText(this.content.sub2).width + 23; // 23 padding between sub1 and sub2
      }

      if (this.content.sub1 && this.content.sub1.length > 0) {
        context.font = '55px FontRegular';
        context.textAlign = 'right';
        context.fillText(`${this.content.sub1}.`, (source.width / 1.642) + widthDifference - widthSub2, canvas.height * 0.719);
      }

      if (this.content.sub3 && this.content.sub3.length > 0) {
        context.font = '55px FontRegular';
        context.textAlign = 'right';
        context.fillText(`${this.content.sub3}.`, (source.width / 1.642) + widthDifference, canvas.height * 0.771);
      }

      // Footer
      context.font = '30px FontRegular';
      context.textAlign = 'right';
      context.fillText('www.mitte.lol', canvas.width - 10, canvas.height - 10);

      this.image = canvas.toDataURL();
    };
  }

  save() {
    saveAs(this.image, `mitte-lol-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.png`);
  }

  reset() {
    this.content.first = this.defaultContent.first;
    this.content.second = this.defaultContent.second;
    this.content.sub1 = this.defaultContent.sub1;
    this.content.sub2 = this.defaultContent.sub2;
    this.content.sub3 = this.defaultContent.sub3;
  }

  randomWords() {
    const item = this.random[Math.floor(Math.random() * this.random.length)];
    if (!item) {
      return;
    }

    this.content.first = item.first;
    this.content.second = item.second;
    this.content.sub1 = item.sub1;
    this.content.sub2 = item.sub2;
    this.content.sub3 = item.sub3;
    this.updateImage();
  }
}
